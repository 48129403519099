import {
  Task,
  TaskViewFilterType,
  TaskViewGroupBy,
  TaskStatus,
  TaskViewSortByField,
  TaskViewSortByDirection,
  PaymentStatus,
  TaskViewType,
  TaskWithOrganization,
  PaymentMethod,
  TaskReward,
  PaymentMethodType,
  TaskPriority,
} from "@dewo/app/graphql/types";
import React, { useCallback, useMemo } from "react";

import { useTaskViewContext } from "./TaskViewContext";
import {
  matchingAssigneeIds,
  matchingDueDate,
  matchingName,
  matchingOrganizationIds,
  matchingOwnerIds,
  matchingPriorities,
  matchingRoles,
  matchingSkillIds,
  matchingStatuses,
  matchingTagIds,
  matchingTemplateIds,
} from "./util";
import _ from "lodash";
import { TaskSectionData } from "../board/util";
import { BatchPayButton } from "../../payment/batch/BatchPayButton";
import { useWorkspace, useWorkspaceDetails } from "../../workspace/hooks";
import { useOrganizationRoles } from "../../rbac/hooks";
import { useTaskViewSearchContext } from "./TaskViewSearchContext";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";

const sortByFieldFns: Partial<
  Record<TaskViewSortByField, (task: Task) => string | number>
> = {
  priority: (t) =>
    [
      TaskPriority.URGENT,
      TaskPriority.HIGH,
      TaskPriority.MEDIUM,
      TaskPriority.LOW,
      TaskPriority.NONE,
    ].indexOf(t.priority),
};

interface TaskViewGroup {
  type: TaskViewGroupBy;
  value: string;
  sections: TaskSectionData[];
}

export function useTaskViewGroups(
  tasks: Task[] | TaskWithOrganization[],
  workspaceId?: string,
  paymentMethods?: PaymentMethod[]
): TaskViewGroup[] {
  const { currentView } = useTaskViewContext();
  const { query } = useTaskViewSearchContext();
  const sortBys = useMemo(
    () =>
      !!currentView?.sortBys.length
        ? currentView.sortBys
        : [
            {
              field: TaskViewSortByField.sortKey,
              direction: TaskViewSortByDirection.ASC,
            },
          ],
    [currentView?.sortBys]
  );
  const groupBy = currentView?.groupBy ?? TaskViewGroupBy.status;

  const { workspace } = useWorkspace(workspaceId);
  const roles = useOrganizationRoles(workspace?.organizationId);

  const details = useWorkspaceDetails(workspaceId).workspace;
  const customSortingSections = details?.taskSections;

  const findFilter = useCallback(
    (type: TaskViewFilterType) =>
      currentView?.filters.find((f) => f.type === type),
    [currentView]
  );
  const filtered = useMemo(
    () =>
      tasks
        .filter((t) => !t.deletedAt)
        .filter(matchingName(query))
        .filter(
          matchingTagIds(
            findFilter(TaskViewFilterType.TAGS)?.tagIds ?? undefined
          )
        )
        .filter(
          matchingSkillIds(
            findFilter(TaskViewFilterType.SKILLS)?.skillIds ?? undefined
          )
        )
        .filter(
          matchingAssigneeIds(
            findFilter(TaskViewFilterType.ASSIGNEES)?.assigneeIds ?? undefined
          )
        )
        .filter(
          matchingOwnerIds(
            findFilter(TaskViewFilterType.OWNERS)?.ownerIds ?? undefined
          )
        )
        .filter(
          matchingStatuses(
            findFilter(TaskViewFilterType.STATUSES)?.statuses ?? undefined
          )
        )
        .filter(
          matchingPriorities(
            findFilter(TaskViewFilterType.PRIORITIES)?.priorities ?? undefined
          )
        )
        .filter(
          matchingRoles(
            findFilter(TaskViewFilterType.ROLES)?.roleIds ?? undefined,
            roles
          )
        )
        .filter(
          matchingTemplateIds(
            findFilter(TaskViewFilterType.TEMPLATE)?.templateIds ?? undefined
          )
        )
        .filter(
          matchingOrganizationIds(
            findFilter(TaskViewFilterType.ORGANIZATION)?.organizationIds ??
              undefined
          )
        )
        .filter(
          matchingDueDate(
            findFilter(TaskViewFilterType.DUE_DATE)?.dueDate ?? undefined
          )
        ),
    [tasks, roles, query, findFilter]
  );

  const groups = useMemo(
    () => _.groupBy(filtered, groupBy),
    [filtered, groupBy]
  );
  const sorted = useMemo(
    () =>
      _.mapValues(groups, (tasks) =>
        _.orderBy(
          tasks,
          sortBys.map((sortBy) => sortByFieldFns[sortBy.field] ?? sortBy.field),
          sortBys.map((sortBy) =>
            sortBy.direction === TaskViewSortByDirection.ASC ? "asc" : "desc"
          )
        )
      ),
    [groups, sortBys]
  );

  const canCreatePayment = usePermission("create", "TaskReward");

  const groupValues = useMemo(() => {
    if (groupBy === TaskViewGroupBy.status) {
      const statusFilter = findFilter(TaskViewFilterType.STATUSES);

      const statuses =
        currentView?.type === TaskViewType.LIST
          ? [
              TaskStatus.IN_REVIEW,
              TaskStatus.IN_PROGRESS,
              TaskStatus.TODO,
              TaskStatus.BACKLOG,
              TaskStatus.DONE,
            ]
          : [
              TaskStatus.BACKLOG,
              TaskStatus.TODO,
              TaskStatus.IN_PROGRESS,
              TaskStatus.IN_REVIEW,
              TaskStatus.DONE,
            ];

      return statuses
        .filter((s): s is TaskStatus => !!s)
        .filter(
          (s) => !statusFilter?.statuses || statusFilter?.statuses?.includes(s)
        );
    }

    return [];
  }, [groupBy, currentView?.type, findFilter]);

  return useMemo<TaskViewGroup[]>(
    () =>
      groupValues.map((value) => {
        const tasks = sorted[value] ?? [];

        const sortKeySorting =
          sortBys[0]?.field === TaskViewSortByField.sortKey;

        if (groupBy === TaskViewGroupBy.status && value === TaskStatus.DONE) {
          const unpaid: Task[] = [];
          const processing: Task[] = [];
          const paid: Task[] = [];

          const isUnpaid = (reward: TaskReward) => !reward.payments.length;
          const isProcessing = (reward: TaskReward) =>
            reward.payments.some(
              (p) => p.payment.status === PaymentStatus.PROCESSING
            );

          const sortedTasks = _.sortBy(tasks, (t) => t.doneAt).reverse();
          sortedTasks.forEach((task) => {
            if (
              !!task.rewards.length &&
              !!task.assignees.length &&
              task.rewards.some(isUnpaid)
            ) {
              unpaid.push(task);
            } else if (task.rewards.some(isProcessing)) {
              processing.push(task);
            } else {
              paid.push(task);
            }
          });

          if (!!unpaid.length || !!processing.length) {
            return {
              type: groupBy,
              value,
              sections: [
                {
                  id: "needs-payment",
                  title: "Needs payment",
                  tasks: unpaid,
                  button: !!paymentMethods && !!canCreatePayment && (
                    <BatchPayButton
                      taskIds={unpaid.map((t) => t.id)}
                      paymentMethods={paymentMethods.filter((pm) =>
                        [
                          PaymentMethodType.GNOSIS_SAFE,
                          PaymentMethodType.UTOPIA,
                        ].includes(pm.type)
                      )}
                    />
                  ),
                },
                {
                  id: "processing-payment",
                  title: "Processing payment",
                  tasks: processing,
                },
                { id: "paid", title: "Paid", tasks: paid },
              ],
            };
          }

          return {
            type: groupBy,
            value,
            sections: [
              { id: "default", tasks: sortedTasks, title: "Uncategorized" },
            ],
          };
        } else if (
          groupBy === TaskViewGroupBy.status &&
          currentView?.type === TaskViewType.BOARD &&
          sortKeySorting &&
          !!customSortingSections
        ) {
          const customSortingSectionsByStatus = customSortingSections.filter(
            (s) => s.status === value
          );
          const [sectioned, unsectioned] = _.partition(tasks, (t) =>
            customSortingSectionsByStatus.some((s) => s.id === t.sectionId)
          );
          const sections: TaskSectionData[] = [];
          _.sortBy(customSortingSectionsByStatus, (s) => s.sortKey)
            .reverse()
            .forEach((section) =>
              sections.push({
                id: section.id,
                title: section.name,
                section,
                tasks: sectioned.filter((t) => t.sectionId === section.id),
              })
            );
          sections.push({
            id: "default",
            tasks: unsectioned,
            title: "Uncategorized",
          });

          return { type: groupBy, value, sections };
        }

        return {
          type: groupBy,
          value,
          sections: [{ id: "default", tasks, title: "Uncategorized" }],
        };
      }),
    [
      groupValues,
      sorted,
      sortBys,
      groupBy,
      currentView?.type,
      customSortingSections,
      paymentMethods,
      canCreatePayment,
    ]
  );
}
