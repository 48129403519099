import { useQuery } from "@apollo/client";
import * as Queries from "@dewo/app/graphql/queries";
import _ from "lodash";
import {
  GetTasksToPayQuery,
  GetTasksToPayQueryVariables,
  PaymentMethod,
  PaymentMethodType,
} from "@dewo/app/graphql/types";
import { useToggle } from "@dewo/app/util/hooks";
import { Button, Divider, Modal, Spin } from "antd";
import React, { FC, Fragment, useMemo } from "react";
import { usePaymentNetworks } from "../hooks";
import { BatchPayTable } from "./BatchPayTable";
import { TaskToPay } from "./types";
import { AddWorkspacePaymentMethodButton } from "../workspace/AddWorkspacePaymentMethodButton";

interface Props {
  taskIds: string[];
  paymentMethods: PaymentMethod[];
}

export const BatchPayButton: FC<Props> = ({ taskIds, paymentMethods }) => {
  const modal = useToggle();

  const tasks = useQuery<GetTasksToPayQuery, GetTasksToPayQueryVariables>(
    Queries.tasksToPay,
    { variables: { input: { ids: taskIds } } }
  ).data?.tasks;

  const tasksByNetworkId = useMemo<Record<string, TaskToPay[]>>(
    () =>
      (tasks ?? []).reduce<Record<string, TaskToPay[]>>((acc, task) => {
        const rewards = task.rewards.filter((r) => !r.payments.length);
        const networkIds = _.uniq(rewards.map((r) => r.token.networkId));
        for (const networkId of networkIds) {
          acc[networkId] = [...(acc[networkId] || []), task];
        }
        return acc;
      }, {}),
    [tasks]
  );

  const paymentMethodsByNetworkId = useMemo<Record<string, PaymentMethod[]>>(
    () => _.groupBy(paymentMethods, (pm) => pm.network.id),
    [paymentMethods]
  );

  const networks = usePaymentNetworks();
  const networksWithTasks = useMemo(
    () => networks?.filter((n) => !!tasksByNetworkId[n.id]?.length),
    [tasksByNetworkId, networks]
  );

  const buttonText = `Batch pay (${taskIds.length})`;

  if (!taskIds || !tasks?.length) return null;
  if (!paymentMethods.length) {
    return (
      <AddWorkspacePaymentMethodButton
        size="small"
        type="primary"
        workspaceId={tasks?.[0].workspaceId}
        initialValues={{ type: PaymentMethodType.GNOSIS_SAFE }}
      >
        {buttonText}
      </AddWorkspacePaymentMethodButton>
    );
  }

  return (
    <>
      <Button type="primary" size="small" onClick={modal.toggleOn}>
        {buttonText}
      </Button>
      <Modal
        visible={modal.isOn}
        width={768}
        footer={null}
        onCancel={modal.toggleOff}
      >
        {!!networksWithTasks && !!tasks ? (
          networksWithTasks.map((network) => (
            <Fragment key={network.id}>
              <Divider style={{ marginTop: 40 }}>{network.name}</Divider>
              <BatchPayTable
                networkId={network.id}
                tasks={tasksByNetworkId[network.id]}
                paymentMethods={paymentMethodsByNetworkId[network.id] ?? []}
                onDone={modal.toggleOff}
              />
            </Fragment>
          ))
        ) : (
          <div style={{ display: "grid", placeItems: "center" }}>
            <Spin />
          </div>
        )}
      </Modal>
    </>
  );
};
