import { PaymentMethod } from "@dewo/app/graphql/types";
import { useRunning } from "@dewo/app/util/hooks";
import { Button, Form, Row, Select, Typography } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { DefaultOptionType } from "antd/lib/select";
import _ from "lodash";
import React, { FC, useCallback, useMemo } from "react";
import { shortenedAddress } from "../hooks";
import { paymentMethodTypeToString } from "../util";
import { AddWorkspacePaymentMethodButton } from "../workspace/AddWorkspacePaymentMethodButton";
import styles from "./BatchPaymentMethodSelect.module.less";

interface FormValues {
  type: string;
  paymentMethodId: string;
}

interface Props {
  paymentMethods: PaymentMethod[];
  workspaceId: string;
  disabled: boolean;
  totalsString: string | undefined;
  onSubmit(paymentMethod: PaymentMethod): void;
}

export const BatchPaymentMethodForm: FC<Props> = ({
  paymentMethods,
  workspaceId,
  disabled,
  totalsString,
  onSubmit,
}) => {
  const [form] = useForm<FormValues>();
  const type = useWatch("type", form);

  const [handleSubmit, submitting] = useRunning(
    useCallback(
      async (values: FormValues) => {
        const method = paymentMethods.find(
          (pm) => pm.id === values.paymentMethodId
        );
        if (!!method) await onSubmit(method);
      },
      [paymentMethods, onSubmit]
    )
  );

  const initialValues = useMemo<Partial<FormValues>>(
    () => ({
      type: paymentMethods[0]?.type,
      paymentMethodId: paymentMethods[0]?.id,
    }),
    [paymentMethods]
  );

  if (!paymentMethods.length) {
    return (
      <AddWorkspacePaymentMethodButton
        size="small"
        type="primary"
        workspaceId={workspaceId}
      >
        Setup payment
      </AddWorkspacePaymentMethodButton>
    );
  }

  return (
    <Form
      form={form}
      requiredMark={false}
      initialValues={initialValues}
      className={styles.form}
      onFinish={handleSubmit}
    >
      <Row align="middle" justify="end" style={{ columnGap: 16 }}>
        <Typography.Text
          strong
          type="secondary"
          className="ant-typography-caption"
          style={{ textTransform: "uppercase" }}
        >
          Wallet:
        </Typography.Text>

        <Form.Item
          name="type"
          rules={[{ required: true }]}
          style={{ margin: 0 }}
          normalize={(value, prevValue) => {
            if (value !== prevValue) {
              form.setFieldsValue({ paymentMethodId: undefined });
            }

            return value;
          }}
        >
          <Select<string, DefaultOptionType>
            size="small"
            style={{ width: 120 }}
            placeholder="Wallet Type..."
            options={_.uniq(paymentMethods.map((pm) => pm.type)).map(
              (type) => ({
                value: type,
                label: paymentMethodTypeToString[type],
              })
            )}
          />
        </Form.Item>

        <Form.Item
          name="paymentMethodId"
          rules={[{ required: true }]}
          style={{ margin: 0 }}
        >
          <Select
            size="small"
            style={{ width: 140 }}
            placeholder="Address..."
            disabled={!type}
            options={_.uniqBy(
              paymentMethods.filter((pm) => pm.type === type),
              (pm) => pm.address
            ).map((pm) => ({
              value: pm.id,
              label: shortenedAddress(pm.address),
            }))}
          />
        </Form.Item>

        {!!totalsString && (
          <Typography.Text
            strong
            type="secondary"
            className="ant-typography-caption"
            style={{ textTransform: "uppercase" }}
          >
            Total: {totalsString}
          </Typography.Text>
        )}

        <Button
          type="primary"
          size="small"
          htmlType="submit"
          loading={submitting}
          disabled={disabled}
        >
          Create Transaction
        </Button>
      </Row>
    </Form>
  );
};
