import { SkillSelect } from "@dewo/app/components/form/SkillSelect";
import { UserSelect } from "@dewo/app/components/form/UserSelect";
import { TaskPriorityIcon } from "@dewo/app/components/icons/task/TaskPriority";
import { TaskStatusIcon } from "@dewo/app/components/icons/task/TaskStatus";
import { RoleTag } from "@dewo/app/components/tags/RoleTag";
import {
  DateRangeFilterInput,
  TaskPriority,
  TaskStatus,
  TaskViewFilterType,
} from "@dewo/app/graphql/types";
import { DatePicker, Form, Row, Select, Space, Typography } from "antd";
import _ from "lodash";
import React, { FC, useCallback, useMemo } from "react";
import { PRIORITY_LABEL, STATUS_LABEL } from "../../board/util";
import { TaskViewTagSelect } from "./TaskViewTagSelect";
import { useTaskViewContext } from "../TaskViewContext";
import { Rule } from "antd/lib/form";
import { FormSection } from "@dewo/app/components/form/FormSection";
import { taskViewFilterTypeToString } from "./TaskViewFormFilterList";
import { useWorkspaceTaskTemplates } from "@dewo/app/containers/workspace/hooks";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { OrganizationAvatar } from "@dewo/app/components/avatars/OrganizationAvatar";
import { useUserOrganizations } from "@dewo/app/containers/graph/hooks";
import moment from "moment";

interface Props {
  name: string | number;
  type: TaskViewFilterType;
  onClear(): void;
}

const atLeastOneRule = (subjectName: string): Rule[] => [
  {
    type: "array",
    min: 1,
    message: `Select at least one ${subjectName}`,
  },
];

const TagFilter: FC<Props> = ({ name, onClear }) => {
  const { tags, currentView } = useTaskViewContext();
  return (
    <Form.Item
      name={[name, "tagIds"]}
      style={{ flex: 1 }}
      rules={atLeastOneRule("tag")}
    >
      <TaskViewTagSelect
        tags={tags}
        allowClear
        organizationId={currentView?.organizationId ?? undefined}
        onClear={onClear}
      />
    </Form.Item>
  );
};

const AssigneeFilter: FC<Props> = ({ name, onClear }) => {
  const { filterableMembers } = useTaskViewContext();
  return (
    <Form.Item
      name={[name, "assigneeIds"]}
      style={{ flex: 1 }}
      rules={atLeastOneRule("assignee")}
    >
      <UserSelect
        mode="multiple"
        placeholder="Select assignees..."
        showUnassigned
        users={filterableMembers}
        onClear={onClear}
      />
    </Form.Item>
  );
};

const OwnerFilter: FC<Props> = ({ name, onClear }) => {
  const { filterableMembers } = useTaskViewContext();
  return (
    <Form.Item
      name={[name, "ownerIds"]}
      style={{ flex: 1 }}
      rules={atLeastOneRule("reviewer")}
    >
      <UserSelect
        mode="multiple"
        placeholder="Select reviewers..."
        users={filterableMembers}
        onClear={onClear}
      />
    </Form.Item>
  );
};

const StatusFilter: FC<Props> = ({ name, onClear }) => {
  return (
    <Form.Item
      name={[name, "statuses"]}
      style={{ flex: 1 }}
      rules={atLeastOneRule("status")}
    >
      <Select
        placeholder="Select task statuses..."
        mode="multiple"
        allowClear
        onClear={onClear}
      >
        {[
          TaskStatus.BACKLOG,
          TaskStatus.TODO,
          TaskStatus.IN_PROGRESS,
          TaskStatus.IN_REVIEW,
          TaskStatus.DONE,
        ]
          .filter((s): s is TaskStatus => !!s)
          .map((status) => (
            <Select.Option key={status} value={status}>
              <Row align="middle" style={{ columnGap: 8 }}>
                <TaskStatusIcon status={status} />
                {STATUS_LABEL[status]}
              </Row>
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

const PriorityFilter: FC<Props> = ({ name, onClear }) => {
  return (
    <Form.Item
      name={[name, "priorities"]}
      style={{ flex: 1 }}
      rules={atLeastOneRule("priority")}
    >
      <Select
        placeholder="Select task priorities..."
        mode="multiple"
        allowClear
        onClear={onClear}
      >
        {[
          TaskPriority.NONE,
          TaskPriority.LOW,
          TaskPriority.MEDIUM,
          TaskPriority.HIGH,
          TaskPriority.URGENT,
        ].map((priority) => (
          <Select.Option key={priority} value={priority}>
            <Space>
              <TaskPriorityIcon priority={priority} size={13} />
              {PRIORITY_LABEL[priority]}
            </Space>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const RoleFilter: FC<Props> = ({ name, onClear }) => {
  const { roles } = useTaskViewContext();
  const organizationRoles = useMemo(
    () => roles?.filter((role) => !role.userId && !role.fallback),
    [roles]
  );

  const roleById = useMemo(() => _.keyBy(roles, (r) => r.id), [roles]);
  return (
    <Form.Item
      name={[name, "roleIds"]}
      style={{ flex: 1 }}
      rules={atLeastOneRule("role")}
    >
      <Select
        mode="multiple"
        placeholder="Select roles..."
        optionFilterProp="label"
        loading={!organizationRoles}
        allowClear
        onClear={onClear}
        tagRender={(props) =>
          !!roleById[props.value] && (
            <RoleTag {...props} role={roleById[props.value]} />
          )
        }
      >
        {organizationRoles?.map((role) => (
          <Select.Option
            key={role.id}
            value={role.id}
            label={role.name}
            style={{ fontWeight: "unset" }}
          >
            <RoleTag role={role} />
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const SkillFilter: FC<Props> = ({ name, onClear }) => (
  <Form.Item
    name={[name, "skillIds"]}
    style={{ flex: 1 }}
    rules={atLeastOneRule("skill")}
  >
    <SkillSelect placeholder="Select skills..." allowClear onClear={onClear} />
  </Form.Item>
);

const TemplateFilter: FC<Props> = ({ name, onClear }) => {
  const { currentView } = useTaskViewContext();
  const templates = useWorkspaceTaskTemplates(currentView?.workspaceId!);

  return (
    <Form.Item name={[name, "templateIds"]}>
      <Select
        mode="multiple"
        placeholder="Select templates..."
        optionFilterProp="label"
        loading={!templates}
        allowClear
        onClear={onClear}
        options={templates?.map((t) => ({
          value: t.id,
          label: t.name,
        }))}
      />
    </Form.Item>
  );
};

const OrganizationFilter: FC<Props> = ({ name, onClear }) => {
  const { user } = useAuthContext();
  const organizations = useUserOrganizations(user);

  return (
    <Form.Item name={[name, "organizationIds"]}>
      <Select
        mode="multiple"
        placeholder="Select organization..."
        optionFilterProp="name"
        loading={!organizations}
        allowClear
        onClear={onClear}
        options={organizations?.map((t) => ({
          value: t.id,
          label: (
            <Space>
              <OrganizationAvatar organization={t} size={20} />
              {t.name}
            </Space>
          ),
          name: t.name,
        }))}
      />
    </Form.Item>
  );
};

const DueDatePicker: FC<{
  value?: DateRangeFilterInput;
  onChange?(value: DateRangeFilterInput | null): void;
  onClear(): void;
}> = ({ value, onChange, onClear }) => (
  <DatePicker.RangePicker
    style={{ width: "100%" }}
    allowEmpty={[true, true]}
    value={useMemo(
      () => [
        !!value?.gte ? moment(value.gte) : null,
        !!value?.lt ? moment(value.lt) : null,
      ],
      [value]
    )}
    onChange={useCallback(
      (values: [moment.Moment | null, moment.Moment | null] | null) => {
        if (!values) {
          onClear();
        } else {
          onChange?.({
            gte: !!values[0]
              ? values[0].utc().startOf("day").toISOString()
              : null,
            lt: !!values[1] ? values[1].utc().endOf("day").toISOString() : null,
          });
        }
      },
      [onChange, onClear]
    )}
    allowClear
  />
);

const DueDateFilter: FC<Props> = ({ name, onClear }) => {
  return (
    <Form.Item name={[name, "dueDate"]}>
      <DueDatePicker onClear={onClear} />
    </Form.Item>
  );
};

const TaskViewFormFilterRowContent: FC<Props> = (props) => {
  switch (props.type) {
    case TaskViewFilterType.TAGS:
      return <TagFilter {...props} />;
    case TaskViewFilterType.ASSIGNEES:
      return <AssigneeFilter {...props} />;
    case TaskViewFilterType.OWNERS:
      return <OwnerFilter {...props} />;
    case TaskViewFilterType.STATUSES:
      return <StatusFilter {...props} />;
    case TaskViewFilterType.PRIORITIES:
      return <PriorityFilter {...props} />;
    case TaskViewFilterType.ROLES:
      return <RoleFilter {...props} />;
    case TaskViewFilterType.SKILLS:
      return <SkillFilter {...props} />;
    case TaskViewFilterType.TEMPLATE:
      return <TemplateFilter {...props} />;
    case TaskViewFilterType.ORGANIZATION:
      return <OrganizationFilter {...props} />;
    case TaskViewFilterType.DUE_DATE:
      return <DueDateFilter {...props} />;
    default:
      return null;
  }
};

export const TaskViewFormFilterRow: FC<Props> = (props) => (
  <FormSection
    label={
      <Typography.Text type="secondary">
        {taskViewFilterTypeToString[props.type]}
      </Typography.Text>
    }
    style={{ marginBottom: 0 }}
  >
    <TaskViewFormFilterRowContent {...props} />
  </FormSection>
);
