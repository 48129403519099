import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { FilterIcon } from "@dewo/app/components/icons/Filter";
import { SortIcon } from "@dewo/app/components/icons/Sort";
import { Button, InputRef, Row } from "antd";
import styles from "./TaskViewTabs.module.less";
import { DebouncedInput } from "@dewo/app/components/form/DebouncedInput";
import classNames from "classnames";
import { useTaskViewSearchContext } from "./TaskViewSearchContext";
import { useRouter } from "next/router";

interface Props {
  className?: string;
  searchPlaceholder?: string;
  showFilter?: boolean;
  showSort?: boolean;
}

export const TaskViewToolbar: FC<Props> = ({
  className,
  showFilter = true,
  showSort = true,
  searchPlaceholder = "Search tasks...",
}) => {
  const router = useRouter();
  const { query, setQuery } = useTaskViewSearchContext();
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setQuery(e.target.value),
    [setQuery]
  );
  const forcefullyShowTaskViewPopover = useCallback(() => {
    const maybeButton = document.querySelector(
      '.ant-tabs-tab-active > div[role="tab"] > div > button'
    ) as HTMLButtonElement | undefined;
    maybeButton?.click();
  }, []);

  const input = useRef<InputRef>(null);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (!!router.query.taskId || !!router.query.applyToTaskId) return;

      if (e.key === "f3" || ((e.ctrlKey || e.metaKey) && e.key === "f")) {
        if (!!input.current) {
          e.preventDefault();
          input.current.focus();
        }
      }
    };
    window.addEventListener("keydown", listener);
    return () => window.removeEventListener("keydown", listener);
  }, [router.query.applyToTaskId, router.query.taskId]);

  return (
    <Row align="middle" className={classNames(className, styles.toolbar)}>
      {showSort && (
        <Button
          size="small"
          icon={<SortIcon />}
          type="text"
          onClick={forcefullyShowTaskViewPopover}
          className="text-secondary"
        >
          Sort
        </Button>
      )}
      {showFilter && (
        <Button
          size="small"
          icon={<FilterIcon />}
          type="text"
          onClick={forcefullyShowTaskViewPopover}
          className="text-secondary"
        >
          Filter
        </Button>
      )}
      <DebouncedInput
        ref={input}
        value={query}
        placeholder={searchPlaceholder}
        style={{
          flex: 1,
          maxWidth: 200,
          minWidth: 120,
        }}
        onChange={handleChange}
      />
    </Row>
  );
};
