import {
  RoleWithRules,
  RulePermission,
  Task,
  TaskPriority,
  TaskStatus,
  TaskViewFilter,
  TaskWithOrganization,
} from "@dewo/app/graphql/types";
import moment from "moment";

export const matchingName = (name: string | undefined) => (t: Task) =>
  !name?.length || t.name.toLowerCase().includes(name.toLowerCase());
export const matchingTagLabels = (labels: string[] | undefined) => (t: Task) =>
  !labels?.length ||
  t.tags.some((tag) => labels.includes(tag.label.toLowerCase()));
export const matchingTagIds = (ids: string[] | undefined) => (t: Task) =>
  !ids?.length || t.tags.some((x) => ids.includes(x.id));
export const matchingSkillIds = (ids: string[] | undefined) => (t: Task) =>
  !ids?.length || t.skills.some((x) => ids.includes(x.id));
export const matchingAssigneeIds =
  (ids: (string | null)[] | undefined) => (t: Task) =>
    !ids?.length ||
    t.assignees.some((x) => ids.includes(x.id)) ||
    (ids.includes(null) && !t.assignees.length);
export const matchingOwnerIds = (ids: string[] | undefined) => (t: Task) =>
  !ids?.length || t.owners.some((x) => ids.includes(x.id));
export const matchingStatuses =
  (statuses: TaskStatus[] | undefined) => (t: Task) =>
    !statuses?.length || statuses.includes(t.status);
export const matchingPriorities =
  (priorities: TaskPriority[] | undefined) => (t: Task) =>
    !priorities?.length || priorities.includes(t.priority);
export const matchingWorkspaces = (ids: string[] | undefined) => (t: Task) =>
  !ids?.length || ids.includes(t.workspaceId);
export const matchingRoles =
  (ids: string[] | undefined, roles: RoleWithRules[] | undefined) =>
  (t: Task) =>
    !ids?.length ||
    !!roles
      ?.filter((r) => ids.includes(r.id))
      .some((r) =>
        r.rules.some(
          (r) =>
            (r.permission === RulePermission.MANAGE_TASKS ||
              r.permission === RulePermission.APPLY_TO_TASKS) &&
            r.taskId === t.id
        )
      );
export const matchingTemplateIds = (ids: string[] | undefined) => (t: Task) =>
  !ids?.length || (!!t.templateTaskId && ids.includes(t.templateTaskId));
export const matchingOrganizationIds =
  (ids: string[] | undefined) => (t: Task | TaskWithOrganization) =>
    !ids?.length ||
    ("workspace" in t && ids.includes(t.workspace.organizationId));
export const matchingDueDate =
  (date: TaskViewFilter["dueDate"] | undefined) =>
  (t: Task | TaskWithOrganization) => {
    if (!!date) {
      if (!t.dueDate) return false;
      if (!!date.gte && moment(t.dueDate).isBefore(date.gte)) {
        return false;
      }
      if (!!date.lt && moment(t.dueDate).isSameOrAfter(date.lt)) {
        return false;
      }
    }
    return true;
  };
