import React, { FC } from "react";
import { List, Typography } from "antd";
import { Task } from "@dewo/app/graphql/types";
import { useNavigateToTask } from "@dewo/app/util/navigation";
import { UpvoteCard } from "./UpvoteCard";
import styles from "./TaskVoting.module.less";
import { TaskCreatorSubtitle } from "@dewo/app/components/task/TaskCreatorSubtitle";

interface Props {
  task: Task;
}

export const TaskVotingListItem: FC<Props> = ({ task }) => {
  const navigateToTask = useNavigateToTask(task.id);
  return (
    <List.Item className={styles.votingCard} onClick={navigateToTask}>
      <List.Item.Meta
        avatar={<UpvoteCard task={task} />}
        title={
          <Typography.Title level={5} className={styles.votingCardTitle}>
            {task.name}
          </Typography.Title>
        }
        description={<TaskCreatorSubtitle task={task} />}
      />
    </List.Item>
  );
};
