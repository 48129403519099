import React from "react";
import Icon from "@ant-design/icons";

const SortSvg = () => (
  <svg viewBox="0 0 16 16" fill="none" width="1em" height="1em">
    <path
      d="M12.0007 2L11.9231 2.00522C11.6442 2.04306 11.4293 2.28213 11.4293 2.57143L11.4286 12.2415L8.97531 9.79001L8.91123 9.7347C8.68746 9.56883 8.36998 9.58742 8.16716 9.79032C7.94415 10.0136 7.9443 10.3754 8.16754 10.5985L11.599 14.027L11.6631 14.0824C11.8869 14.2482 12.2043 14.2296 12.4071 14.0267L15.8328 10.5982L15.8881 10.534C16.054 10.3102 16.0354 9.99276 15.8324 9.79001L15.7684 9.7347C15.5446 9.56883 15.2271 9.58742 15.0243 9.79032L12.5714 12.2446L12.5721 2.57143L12.5668 2.49389C12.5291 2.21497 12.29 2 12.0007 2ZM3.59193 2.16738L0.167353 5.59218L0.112031 5.65627C-0.0539498 5.87998 -0.0354965 6.19744 0.167383 6.4003L0.231475 6.45563C0.455185 6.62164 0.77264 6.60312 0.975505 6.40028L3.42628 3.94827L3.42679 13.6228L3.43201 13.7003C3.46984 13.9793 3.70893 14.1942 3.99822 14.1942L4.07575 14.189C4.35467 14.1512 4.56964 13.9121 4.56964 13.6228L4.56914 3.9498L7.02468 6.40073L7.08883 6.45599C7.31268 6.62171 7.63009 6.60297 7.83276 6.39985C8.05569 6.17646 8.05531 5.81464 7.83192 5.59173L4.39962 2.16693L4.33553 2.1117C4.11182 1.94606 3.79464 1.96466 3.59193 2.16738Z"
      fill="currentColor"
    />
  </svg>
);

export const SortIcon = (props: any) => <Icon component={SortSvg} {...props} />;
