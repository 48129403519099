import {
  AppstoreOutlined,
  ProjectOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { TaskViewType } from "@dewo/app/graphql/types";
import { Form, Radio } from "antd";
import React, { ComponentType, FC } from "react";

interface RadioOption {
  icon: ComponentType<any>;
  value: TaskViewType;
  label: string;
}

const options: RadioOption[] = [
  { icon: ProjectOutlined, value: TaskViewType.BOARD, label: "Board" },
  { icon: UnorderedListOutlined, value: TaskViewType.LIST, label: "List" },
  { icon: AppstoreOutlined, value: TaskViewType.GRID, label: "Grid" },
];

export const TaskViewTypeRadioGroup: FC = () => (
  <Form.Item key="type" name="type" style={{ margin: 0 }}>
    <Radio.Group buttonStyle="solid" size="small">
      {options.map((option) => (
        <Radio.Button
          key={option.value}
          value={option.value}
          style={{
            minWidth: 72,
            display: "inline-grid",
            placeItems: "center",
          }}
        >
          <option.icon style={{ marginRight: 4 }} />
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  </Form.Item>
);
