import { Task, TaskViewField } from "@dewo/app/graphql/types";
import { useNavigateToTask } from "@dewo/app/util/navigation";
import { Card, Row, Typography } from "antd";
import classNames from "classnames";
import moment from "moment";
import React, { FC, useMemo } from "react";
import {
  TaskActionButton,
  useTaskActionButton,
} from "../actions/TaskActionButton";
import { TaskTagsRow } from "../card/TaskTagsRow";
import { TaskGatingLabel } from "../form/gating/TaskGatingLabel";
import { MultipleTaskRewardsTag } from "../reward/TaskRewardTag";
import { useTaskViewFields } from "../views/TaskViewFieldsContext";
import removeMarkdown from "remove-markdown";
import styles from "./TaskGrid.module.less";

interface Props {
  task: Task;
}

export const TaskGridCard: FC<Props> = ({ task }) => {
  const navigateToTask = useNavigateToTask(task.id);
  const fields = useTaskViewFields();
  const taskTagsRowFields = useMemo(
    () => new Set(Array.from(fields).filter((v) => v !== TaskViewField.reward)),
    [fields]
  );

  const shouldRenderReward =
    !!task.rewards.length && fields.has(TaskViewField.reward);
  const shouldRenderTaskActionButton =
    !!useTaskActionButton(task) && fields.has(TaskViewField.button);

  return (
    <Card
      bordered={false}
      size="small"
      className={classNames("hover:component-highlight", styles.card)}
      onClick={navigateToTask}
      actions={
        shouldRenderReward || shouldRenderTaskActionButton
          ? [
              <Row
                key="footer"
                align="middle"
                justify="space-between"
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  flexWrap: "nowrap",
                }}
              >
                {shouldRenderReward && (
                  <MultipleTaskRewardsTag rewards={task.rewards} />
                )}
                {shouldRenderTaskActionButton && (
                  <>
                    <div />
                    <TaskActionButton key="action" task={task} />
                  </>
                )}
              </Row>,
            ]
          : undefined
      }
    >
      <Row align="middle" justify="space-between" style={{ marginBottom: 4 }}>
        <TaskGatingLabel gating={task.gating} className={styles.gatingLabel} />
        {fields.has(TaskViewField.createdAt) && (
          <Typography.Text type="secondary" className="ant-typography-caption">
            {moment(task.createdAt).fromNow()}
          </Typography.Text>
        )}
      </Row>
      <Typography.Title
        level={5}
        style={{ wordBreak: "break-word", marginBottom: 8, fontWeight: 500 }}
        ellipsis={{ rows: 2 }}
      >
        {task.name}
      </Typography.Title>
      <TaskTagsRow
        task={task}
        style={{ marginBottom: 16, flexFlow: "nowrap" }}
        showPriority
        fields={taskTagsRowFields}
      />
      <Typography.Paragraph
        type="secondary"
        ellipsis={{ rows: 3 }}
        style={{ marginBottom: 0, fontSize: 13 }}
        className="ant-typography-caption"
      >
        {!!task.description
          ? removeMarkdown(task.description.replace(/\\/g, "")).trim()
          : "No description..."}
      </Typography.Paragraph>
    </Card>
  );
};
