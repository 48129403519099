import { ApplicationIcon } from "@dewo/app/components/icons/task/Application";
import { ClaimableIcon } from "@dewo/app/components/icons/task/Claimable";
import { ContestIcon } from "@dewo/app/components/icons/task/Contest";
import { LockIcon } from "@dewo/app/components/icons/task/Lock";
import { TaskGatingType } from "@dewo/app/graphql/types";
import { Row, Typography } from "antd";
import React, { FC } from "react";

interface Props {
  gating: TaskGatingType;
  size?: number;
  className?: string;
}

export const TaskGatingLabel: FC<Props> = ({
  gating,
  className,
  size = 10,
}) => {
  if (gating === TaskGatingType.ASSIGNEES) {
    return (
      <Row align="middle" style={{ columnGap: 4 }}>
        <LockIcon style={{ width: size }} />
        <Typography.Text
          type="secondary"
          className={className}
          style={{ fontSize: size }}
        >
          Assigned
        </Typography.Text>
      </Row>
    );
  }

  if (gating === TaskGatingType.OPEN_SUBMISSION) {
    return (
      <Row align="middle" style={{ columnGap: 4 }}>
        <ContestIcon style={{ width: size }} />
        <Typography.Text
          className={className}
          style={{ color: "#EFB72A", fontSize: size }}
        >
          Open to Submissions
        </Typography.Text>
      </Row>
    );
  }

  if (gating === TaskGatingType.APPLICATION) {
    return (
      <Row align="middle" style={{ columnGap: 4 }}>
        <ApplicationIcon style={{ width: size }} />
        <Typography.Text
          className={className}
          style={{ color: "#73C4FF", fontSize: size }}
        >
          Open to Applications
        </Typography.Text>
      </Row>
    );
  }

  if (gating === TaskGatingType.ROLES) {
    return (
      <Row align="middle" style={{ columnGap: 4 }}>
        <ClaimableIcon style={{ width: size }} />
        <Typography.Text
          className={className}
          style={{ color: "#FF78B9", fontSize: size }}
        >
          Open to Roles
        </Typography.Text>
      </Row>
    );
  }

  return null;
};
