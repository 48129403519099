import { Button, Input, Modal, Row, Typography } from "antd";
import React, { FC, useCallback, useMemo, useState } from "react";
import {
  useUpdateTaskSection,
  useCreateTaskSection,
  useDeleteTaskSection,
  useWorkspaceDetails,
} from "../../workspace/hooks";

import { TaskSection, TaskStatus } from "@dewo/app/graphql/types";
import { useRunningCallback } from "@dewo/app/util/hooks";
import _ from "lodash";
import { ReorderList } from "@dewo/app/components/reorder/ReorderList";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

interface Props {
  visible: boolean;
  workspaceId: string;
  status: TaskStatus;
  onClose(): void;
}

const TaskSectionRow: FC<{ section: TaskSection }> = ({ section }) => {
  const updateSection = useUpdateTaskSection();
  const [handleUpdateName, updatingName] = useRunningCallback(
    async (name: string) => {
      await updateSection(
        { id: section.id, workspaceId: section.workspaceId, name },
        section
      );
    },
    [updateSection, section]
  );

  const deleteSection = useDeleteTaskSection();
  const [handleDelete, deleting] = useRunningCallback(
    () =>
      deleteSection({
        id: section.id,
        workspaceId: section.workspaceId,
        deletedAt: new Date().toISOString(),
      }),
    [deleteSection, section]
  );

  return (
    <>
      <Typography.Paragraph
        style={{ flex: 1, marginBottom: 0, marginLeft: 8 }}
        editable={{ triggerType: ["text"], onChange: handleUpdateName }}
      >
        {section.name}
      </Typography.Paragraph>
      {updatingName && <LoadingOutlined />}
      <Button
        loading={deleting}
        icon={<DeleteOutlined />}
        size="small"
        type="text"
        className="text-secondary"
        onClick={handleDelete}
      />
    </>
  );
};

export const ReorderTaskSectionsModal: FC<Props> = ({
  visible,
  workspaceId,
  status,
  onClose,
}) => {
  const { workspace } = useWorkspaceDetails(workspaceId);
  const sections = useMemo(
    () =>
      _.sortBy(
        workspace?.taskSections.filter((s) => s.status === status),
        (s) => s.sortKey
      ).reverse(),
    [workspace, status]
  );

  const [newSectionName, setNewSectionName] = useState("");
  const createSection = useCreateTaskSection();
  const [handleCreate, creating] = useRunningCallback(async () => {
    if (!newSectionName) return;
    await createSection({ name: newSectionName, status, workspaceId });
    setNewSectionName("");
  }, [createSection, newSectionName, status, workspaceId]);

  const updateSection = useUpdateTaskSection();
  const handleReorder = useCallback(
    async (section: TaskSection, sortKey: string) =>
      updateSection(
        { id: section.id, workspaceId: section.workspaceId, sortKey },
        section
      ),
    [updateSection]
  );

  if (!workspace) return null;
  return (
    <Modal visible={visible} onCancel={onClose} width={368} footer={null}>
      <Typography.Title level={5} style={{ textAlign: "center" }}>
        Manage Sections
      </Typography.Title>
      <Typography.Paragraph type="secondary" style={{ textAlign: "center" }}>
        Create, reorder and remove task sections
      </Typography.Paragraph>

      <Row align="middle" style={{ marginLeft: 12 }}>
        <Button
          icon={<PlusCircleOutlined />}
          shape="circle"
          size="small"
          type="text"
          className="text-secondary"
          loading={creating}
          onClick={handleCreate}
        />
        <Input.TextArea
          autoSize
          className="dewo-field dewo-field-focus-border"
          style={{ flex: 1 }}
          placeholder="Add section..."
          disabled={creating}
          value={newSectionName}
          onChange={(event) => setNewSectionName(event.target.value)}
          onPressEnter={handleCreate}
        />
      </Row>
      <ReorderList
        fieldName="sortKey"
        dataSource={sections}
        onReorder={handleReorder}
        renderLabel={(section) => <TaskSectionRow section={section} />}
      />
    </Modal>
  );
};
