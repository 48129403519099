import { useToggle } from "@dewo/app/util/hooks";
import { Button, ButtonProps } from "antd";
import React, { FC } from "react";
import { AddWorkspacePaymentMethodModal } from "./AddWorkspacePaymentMethodModal";
import { FormValues } from "./WorkspacePaymentMethodForm";

interface Props extends ButtonProps {
  workspaceId: string;
  initialValues?: Partial<FormValues>;
}

export const AddWorkspacePaymentMethodButton: FC<Props> = ({
  workspaceId,
  initialValues,
  ...buttonProps
}) => {
  const addPaymentMethod = useToggle();
  return (
    <>
      <Button {...buttonProps} onClick={addPaymentMethod.toggleOn} />
      <AddWorkspacePaymentMethodModal
        initialValues={initialValues}
        visible={addPaymentMethod.isOn}
        onClose={addPaymentMethod.toggleOff}
        workspaceId={workspaceId}
      />
    </>
  );
};
