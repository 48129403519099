import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import {
  PaymentMethod,
  PaymentMethodType,
  TaskRewardPaymentInput,
} from "@dewo/app/graphql/types";
import { useSwitchChain } from "@dewo/app/util/ethereum";
import { useProposeTransaction } from "@dewo/app/util/gnosis";
import { MetaTransactionData } from "@gnosis.pm/safe-core-sdk-types";
import { notification, Table, Tag } from "antd";
import React, { FC, useCallback, useMemo, useState } from "react";
import { formatTaskReward } from "../../task/hooks";
import { BatchPaymentMethodForm } from "./BatchPaymentMethodSelect";
import {
  useBatchTableRows,
  useCreateTaskPayments,
  usePaymentTotalString,
  usePrepareGnosisTransaction,
} from "./hooks";
import { TableRow, TaskToPay } from "./types";

interface Props {
  networkId: string;
  tasks: TaskToPay[];
  paymentMethods: PaymentMethod[];
  onDone(): void;
}

export const BatchPayTable: FC<Props> = ({
  tasks,
  paymentMethods,
  networkId,
  onDone,
}) => {
  const rows = useBatchTableRows(tasks, networkId);

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>(() =>
    rows.filter((r) => !!r.address).map((r) => r.id)
  );
  const selectedRows = useMemo(
    () => rows.filter((r) => selectedRowIds.includes(r.id)),
    [rows, selectedRowIds]
  );

  const workspaceIdToCreateNewPaymentMethodIn = useMemo(
    () => tasks[0]?.workspaceId,
    [tasks]
  );

  const proposeGnosisTransaction = useProposeTransaction();
  const prepareGnosisTransaction = usePrepareGnosisTransaction();
  const createTaskPayments = useCreateTaskPayments();
  const switchChain = useSwitchChain();

  const submit = useCallback(
    async (paymentMethod: PaymentMethod) => {
      try {
        const network = paymentMethod.network;
        await switchChain(network);

        const rowsToPay = rows.filter(
          (r) => selectedRowIds.includes(r.id) && !!r.address
        );

        const payments: TaskRewardPaymentInput[] = rowsToPay.map((row) => ({
          rewardId: row.reward.id,
          userId: row.user.id,
          amount: row.amount,
          tokenId: row.token.id,
        }));

        if (
          [PaymentMethodType.GNOSIS_SAFE, PaymentMethodType.UTOPIA].includes(
            paymentMethod.type
          )
        ) {
          const transactions = await Promise.all(
            rowsToPay.map(
              async (row): Promise<MetaTransactionData> =>
                prepareGnosisTransaction(
                  row.amount,
                  row.token,
                  paymentMethod.address,
                  row.address!,
                  network
                )
            )
          );

          const safeTxHash = await proposeGnosisTransaction(
            paymentMethod.address,
            transactions,
            network
          );

          await createTaskPayments({
            payments,
            paymentMethodId: paymentMethod.id,
            data: { safeTxHash },
          });
        }

        onDone();
      } catch (error) {
        notification.info({
          message: "Payment failed",
          description: (error as Error).message,
        });
      }
    },
    [
      proposeGnosisTransaction,
      createTaskPayments,
      switchChain,
      prepareGnosisTransaction,
      onDone,
      selectedRowIds,
      rows,
    ]
  );

  const totalsString = usePaymentTotalString(selectedRows);

  return (
    <>
      <Table<TableRow>
        dataSource={rows}
        size="small"
        rowKey="id"
        pagination={{ hideOnSinglePage: true }}
        rowSelection={{
          selectedRowKeys: selectedRowIds,
          onChange: (ids) => setSelectedRowIds(ids as string[]),
          getCheckboxProps: (row) => ({ disabled: !row.address }),
        }}
        columns={[
          {
            key: "avatar",
            width: 1,
            render: (_, row) => <UserAvatar user={row.user} />,
          },
          { title: "Task", dataIndex: ["task", "name"] },
          {
            title: "Payment",
            key: "reward",
            width: 120,
            render: (_, row) =>
              !!row.address ? (
                formatTaskReward({
                  token: row.token,
                  amount: row.amount,
                  peggedToUsd: false,
                })
              ) : (
                <Tag color="red">
                  Contributor needs to add their payment address
                </Tag>
              ),
          },
        ]}
        footer={() => (
          <BatchPaymentMethodForm
            disabled={!selectedRowIds.length}
            workspaceId={workspaceIdToCreateNewPaymentMethodIn}
            paymentMethods={paymentMethods}
            totalsString={totalsString}
            onSubmit={submit}
          />
        )}
      />
    </>
  );
};
