import React, { FC, ReactElement, useMemo } from "react";
import { Button, List, Skeleton } from "antd";
import { SearchTasksInput } from "@dewo/app/graphql/types";
import styles from "./TaskVoting.module.less";
import {
  TaskViewLayoutData,
  taskViewToSearchInput,
  useTaskViewLayoutData,
} from "../views/hooks";
import { useTaskViewContext } from "../views/TaskViewContext";
import { useTaskViewSearchContext } from "../views/TaskViewSearchContext";
import { TaskVotingListItem } from "./TaskVotingListItem";

interface Props {
  data: TaskViewLayoutData;
  renderEmpty?(): ReactElement;
}

export const TaskVotingListFromView: FC<Omit<Props, "data">> = (props) => {
  const { currentView, showWorkspaceTag } = useTaskViewContext();
  const search = useTaskViewSearchContext();
  const [data] = useTaskViewLayoutData(
    useMemo(
      (): SearchTasksInput[] =>
        !!currentView ? [taskViewToSearchInput(currentView, search.query)] : [],
      [currentView, search.query]
    ),
    { withOrganization: showWorkspaceTag }
  );
  return <TaskVotingList {...props} data={data} />;
};

export const TaskVotingList: FC<Props> = ({ data, renderEmpty }) => {
  if (!data.tasks?.length) {
    if (data.loading) return <Skeleton />;
    return renderEmpty?.() ?? null;
  }

  return (
    <List
      dataSource={data.tasks}
      className={styles.votingList}
      renderItem={(task) => <TaskVotingListItem task={task} />}
      footer={
        <>
          {data.hasMore && !!data.tasks && !data.loading && (
            <Button
              block
              type="text"
              style={{ marginTop: 16 }}
              onClick={data.fetchMore}
            >
              Load more
            </Button>
          )}
          {data.loading && <Skeleton />}
        </>
      }
    />
  );
};
