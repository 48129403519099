import React from "react";
import Icon from "@ant-design/icons";

const FilterSvg = () => (
  <svg viewBox="0 0 16 16" fill="none" width="1em" height="1em">
    <path
      d="M9.45455 11.697C9.85619 11.697 10.1818 12.0226 10.1818 12.4242C10.1818 12.8259 9.85619 13.1515 9.45455 13.1515H6.54545C6.14381 13.1515 5.81818 12.8259 5.81818 12.4242C5.81818 12.0226 6.14381 11.697 6.54545 11.697H9.45455ZM12.3636 6.84849C12.7653 6.84849 13.0909 7.17411 13.0909 7.57576C13.0909 7.97741 12.7653 8.30303 12.3636 8.30303H3.63636C3.23471 8.30303 2.90909 7.97741 2.90909 7.57576C2.90909 7.17411 3.23471 6.84849 3.63636 6.84849H12.3636ZM15.2727 2C15.6744 2 16 2.32561 16 2.72727C16 3.12893 15.6744 3.45455 15.2727 3.45455H0.727273C0.325615 3.45455 0 3.12893 0 2.72727C0 2.32561 0.325615 2 0.727273 2H15.2727Z"
      fill="currentColor"
    />
  </svg>
);

export const FilterIcon = (props: any) => (
  <Icon component={FilterSvg} {...props} />
);
