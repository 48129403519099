import { TaskStatus } from "@dewo/app/graphql/types";
import { Button, Col, Row, Skeleton } from "antd";
import React, { FC, Fragment, useMemo, useState } from "react";
import {
  TaskViewGroupHeader,
  TaskViewGroupHeaderExtra,
} from "../board/TaskViewGroupHeader";
import {
  TaskViewLayoutData,
  useTaskViewLayoutData,
  useTaskViewLayoutItems,
} from "../views/hooks";
import { useTaskViewContext } from "../views/TaskViewContext";
import { TaskGridCard } from "./TaskGridCard";

interface Props {
  showHeaders?: boolean;
  // style?: CSSProperties;
  className?: string;
  data: TaskViewLayoutData[];
}

export const TaskGridFromView: FC<Omit<Props, "data">> = (props) => {
  const { showWorkspaceTag } = useTaskViewContext();
  const items = useTaskViewLayoutItems();
  const queries = useMemo(() => items.map((i) => i.query), [items]);
  const data = useTaskViewLayoutData(queries, {
    withOrganization: showWorkspaceTag,
  });
  return <TaskGrid {...props} data={data} />;
};

export const TaskGrid: FC<Props> = ({
  showHeaders = true,
  data,
  className,
}) => {
  const [collapsed, setCollapsed] = useState<Record<string, boolean>>({});
  const view = useTaskViewContext().currentView;
  const workspaceId = view?.workspaceId ?? undefined;

  return (
    <div className={className}>
      {data.map((d) => {
        const status = d.filter.statuses?.[0] as TaskStatus;
        return (
          <Fragment key={status}>
            {showHeaders && (
              <Row style={{ padding: 12 }}>
                <TaskViewGroupHeader
                  showIcon
                  count={d.total ?? 0}
                  status={status}
                  collapse={{
                    collapsed: !!collapsed[status],
                    onToggle: (collapsed) =>
                      setCollapsed((p) => ({ ...p, [status]: collapsed })),
                  }}
                />
                <TaskViewGroupHeaderExtra
                  status={status}
                  workspaceId={workspaceId}
                />
              </Row>
            )}
            {!collapsed[status] && (
              <Row gutter={[16, 16]}>
                {d.tasks?.map((task) => (
                  <Col key={task.id} xs={24} sm={12} xl={8} xxl={8}>
                    <TaskGridCard task={task} />
                  </Col>
                ))}
              </Row>
            )}
            {!collapsed[status] && d.hasMore && !!d.tasks && !d.loading && (
              <Button
                block
                type="text"
                style={{ marginTop: 16 }}
                onClick={d.fetchMore}
              >
                Load more
              </Button>
            )}
            {d.loading && <Skeleton />}
          </Fragment>
        );
      })}
    </div>
  );
};
