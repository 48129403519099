import { TaskStatus } from "@dewo/app/graphql/types";
import { Card, Row, Skeleton, Space } from "antd";
import React from "react";
import { FC } from "react";
import { TaskViewGroupHeader } from "./TaskViewGroupHeader";

export const SkeletonTaskBoard: FC = () => {
  const statuses: TaskStatus[] = [
    TaskStatus.TODO,
    TaskStatus.IN_PROGRESS,
    TaskStatus.IN_REVIEW,
    TaskStatus.DONE,
  ];
  const columnWidth = 330;
  return (
    <Row className="dewo-task-board">
      <Space size="middle" align="start">
        {statuses.map((status) => (
          <div key={status} style={{ width: columnWidth }}>
            <Card
              size="small"
              title={<TaskViewGroupHeader showIcon count={0} status={status} />}
              className="dewo-task-board-column"
            >
              <Skeleton />
            </Card>
          </div>
        ))}
      </Space>
    </Row>
  );
};
