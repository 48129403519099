import { useMutation } from "@apollo/client";
import * as Mutations from "@dewo/app/graphql/mutations";
import { BigNumber } from "ethers";
import {
  CreateTaskPaymentsInput,
  CreateTaskPaymentsMutation,
  CreateTaskPaymentsMutationVariables,
  PaymentNetwork,
  PaymentToken,
  PaymentTokenType,
  ThreepidSource,
} from "@dewo/app/graphql/types";
import { Constants } from "@dewo/app/util/constants";
import { useERC1155Contract, useERC20Contract } from "@dewo/app/util/ethereum";
import { MetaTransactionData } from "@gnosis.pm/safe-core-sdk-types";
import { useCallback, useMemo } from "react";
import { TableRow, TaskToPay } from "./types";
import _ from "lodash";
import { formatTaskReward } from "../../task/hooks";

export function usePrepareGnosisTransaction(): (
  amount: string,
  token: PaymentToken,
  fromAddress: string,
  toAddress: string,
  network: PaymentNetwork
) => Promise<MetaTransactionData> {
  const loadERC20Contract = useERC20Contract();
  const loadERC1155Contract = useERC1155Contract();
  return useCallback(
    async (amount, token, fromAddress, toAddress, network) => {
      if (token.type === PaymentTokenType.ERC20 && !!token.address) {
        const contract = await loadERC20Contract(token.address, network);
        // https://ethereum.stackexchange.com/a/116793/89347
        // https://github.com/ethers-io/ethers.js/issues/478#issuecomment-495814010
        return {
          to: token.address,
          value: "0",
          data: contract.interface.encodeFunctionData("transfer", [
            toAddress,
            amount.toString(),
          ]),
        };
      }

      if (token.type === PaymentTokenType.ERC1155 && !!token.address) {
        const contract = await loadERC1155Contract(token.address, network);
        return {
          to: token.address,
          value: "0",
          data: contract.interface.encodeFunctionData("safeTransferFrom", [
            fromAddress,
            toAddress,
            token.identifier,
            amount.toString(),
            "0x",
          ]),
        };
      }

      return {
        to: toAddress,
        value: amount.toString(),
        data: "0x",
      };
    },
    [loadERC20Contract, loadERC1155Contract]
  );
}

export function useCreateTaskPayments(): (
  input: CreateTaskPaymentsInput
) => Promise<void> {
  const [mutation] = useMutation<
    CreateTaskPaymentsMutation,
    CreateTaskPaymentsMutationVariables
  >(Mutations.createTaskPayments);
  return useCallback(
    async (input) => {
      const res = await mutation({ variables: { input } });
      if (!res.data) throw new Error(JSON.stringify(res.errors));
    },
    [mutation]
  );
}

export function useBatchTableRows(
  tasks: TaskToPay[],
  networkId: string
): TableRow[] {
  return useMemo<TableRow[]>(
    () =>
      tasks
        .map((task) =>
          task.rewards
            .filter((reward) => reward.token.networkId === networkId)
            .filter((reward) => !reward.payments.length)
            .map((reward) => ({ task, reward }))
        )
        .flat()
        .map(({ task, reward }) => {
          const usdPriceAccuracy = Math.pow(
            10,
            Constants.NUM_DECIMALS_IN_USD_PEG
          );
          const amount = reward.peggedToUsd
            ? BigNumber.from(reward.amount)
                .mul(BigNumber.from(10).pow(reward.token.exp))
                .mul(BigNumber.from(usdPriceAccuracy))
                .div(
                  BigNumber.from(
                    Math.round(reward.token.usdPrice! * usdPriceAccuracy)
                  )
                )
                .div(BigNumber.from(10).pow(Constants.NUM_DECIMALS_IN_USD_PEG))
            : BigNumber.from(reward.amount);

          if (
            [PaymentTokenType.ERC721, PaymentTokenType.ERC1155].includes(
              reward.token.type
            )
          ) {
            const canSplitNftsEqually = amount.mod(task.assignees.length).eq(0);
            if (!canSplitNftsEqually) {
              const user = task.assignees[0];
              return [
                {
                  id: [task.id, user.id, reward.id].join("/"),
                  task,
                  reward,
                  user,
                  address: user.threepids.find(
                    (t) => t.source === ThreepidSource.metamask
                  )?.address,
                  token: reward.token,
                  amount: reward.amount,
                },
              ];
            }
          }

          return task.assignees.map((user) => ({
            id: [task.id, user.id, reward.id].join("/"),
            task,
            reward,
            user,
            address: user.threepids.find(
              (t) => t.source === ThreepidSource.metamask
            )?.address,
            token: reward.token,
            amount: amount.div(task.assignees.length).toString(),
          }));
        })
        .flat(),
    [tasks, networkId]
  );
}

export function usePaymentTotalString(rows: TableRow[]): string | undefined {
  return useMemo(() => {
    if (!rows.length) return undefined;

    const tokenById = _.keyBy(
      rows.map((r) => r.token),
      "id"
    );
    const amountByTokenId = rows.reduce<{ [tokenId: string]: BigNumber }>(
      (acc, row) => {
        const amount = BigNumber.from(row.amount);
        const tokenId = row.reward.token.id;
        if (!acc[tokenId]) acc[tokenId] = BigNumber.from(0);
        acc[tokenId] = acc[tokenId].add(amount);
        return acc;
      },
      {}
    );

    return Object.keys(amountByTokenId)
      .map((tokenId) =>
        formatTaskReward({
          token: tokenById[tokenId],
          amount: amountByTokenId[tokenId].toString(),
          peggedToUsd: false,
        })
      )
      .join(", ");
  }, [rows]);
}
